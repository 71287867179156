import useFeatureToggles from "src/core/common/hooks/useFeatureToggles";
import {makeIdentityVerification} from "src/integrations/identityVerification/factory";

const IdentityVerificationMain = () => {
  const toggles = useFeatureToggles();
  const identityVerificationService = toggles.getIdentityVerificationServiceName();
  const identityVerification = makeIdentityVerification(identityVerificationService);
  return identityVerification.renderMainComponent();
};

export default IdentityVerificationMain;
